
input {
  height: 48px;
  padding: 0 14px;
  border-radius: 4px;
  border: 1px solid #e6e8eb;
  background-color: #f9fafa;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 46px;
  outline: none;
  color: #424242;
}
input[type=text] {
  flex: 1 1 auto;
  padding: 0 14px;
  border: 0;
  background-color: #FFF;
  &:disabled {
    background-color: #E6E8EB;
    color: #737373;
  }
}
input::placeholder {
  color: #9E9E9E;
}
