

.bg-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.4);
  z-index: 103;
  .modal-wrap {
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFF;
    border-radius: 16px;
    width: calc(min(100% - 32px, 327px));
    .modal-header {
      display: flex;
      justify-content: space-between;
      padding: 15px;
      border-bottom: 1px solid #E6E8EB
    }
    .modal-content {
      padding: 16px 16px 24px;
    }
  }
}
