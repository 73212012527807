
:root {
  --primary-color: #14beb3;
}
html, body {
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  min-height: 100%;
  font-size: 14px;
  /* letter-spacing: -0.5px; */
  color: #000;
  height: 100%;
  min-height: -webkit-fill-available;
}
.screen_out {
  overflow: hidden;
  position: absolute;
  width: 0;
  height: 0;
  line-height: 0;
  text-indent: -9999px;
}
.loading {
  width:100%;
  height:100%;
  top: 0;
  z-index: 14;
  position:fixed;
  // background: #00000033;
}
@keyframes loading {
  0% {
    backdrop-filter: blur(0px);
  }
  50% {
    backdrop-filter: blur(2px);
  }
  100% {
    backdrop-filter: blur(0px);
  }
}
.circle {
  top:0;
  bottom:0;
  left:0;
  right:0;
  margin : auto;
  position: absolute;
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 5px solid rgba(255,255,255,.3);
  border-radius: 50%;
  border-top-color: #fff;
  border-top-color: var(--primary-color);
  animation: spin 1s ease-in-out infinite;
  animation: spin .5s linear infinite;
}
@keyframes spin {
  to { transform: rotate(360deg); }
}
