
.tab-content{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background: #fff;
    // padding: 5px;
    // min-height: 400px;
    box-sizing: border-box;
    overflow: auto;
    // background: #33333333;
}
