

.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.toast-message {
  position: fixed;
  display: flex;
  align-items: center;
  left: 50%;
  width: 100%;
  max-width: calc(100% - 32px);
  padding: 13px 16px;
  border-radius: 6px;
  background-color: rgba(48, 53, 56, 0.95);
  transform: translateX(-50%);
  z-index: 1000;
  svg {
    margin-right: 8px;
  }
  p {
    font-size: 14px;
    color: #FFF;
    line-height: 23px;
  }
  .action-button {
    flex: 0 0 auto;
    margin-left: auto;
    padding-left: 8px;
    font-weight: 700;
    font-size: 14px;
    color: #FFF;
    line-height: 23px;
  }
}
@media screen and (min-width: 769px) {
  .toast-message {
    width: auto;
    min-width: 343px;
  }
}
