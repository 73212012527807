
.tab {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  .groups {
    display: flex;
    height: 42px;
    list-style-type: none;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    padding: 0 16px;
    margin: 0;
    overflow: visible;
    border-bottom: 1px solid #E6E8EB;
    .tab-button {
      width:100%;
      min-width:50px;
      font-weight: 500;
      letter-spacing: -0.2px;
      .tab-label {
        font-size: 16px;
        cursor: pointer;
        display: block;
        margin:0;
        padding-bottom: 5px;
        position: relative;
        padding: 0 4px;
        height: 40px;
        color: #A0A0A0;
        line-height: 40px;
        background-color: transparent;
      }
      .active {
        font-weight: 600;
        color: #000;
        &:after {
          display: block;
          position: absolute;
          left: 0;
          bottom: -1px;
          width: 100%;
          height: 2px;
          background-color: #000;
          content: "";
        }
      }
    }
  }
}

