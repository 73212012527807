
.category-item {
  display: inline-flex;
  font-weight: 500;
  height: 36px;
  line-height: 36px;
  margin:0 3px;
  padding: 0 9px 0 15px;
  border-radius:100px;
  border:1px solid #D1D5D9;
  background-color:#fff;
  &.active {
    border:1px solid #636C73;
  }
  .ico {
    // background: red;
    margin: 0 4px;
    height: 34px;
    // float: right;
    flex: 0 0 auto;
  }
}
.dropdown-wrap {
  position: relative;
  display: inline-block;
}
.dropdown-list {
  display: none;
  overflow: hidden auto;
  max-height: calc(min((var(--vh, 1vh) * 100)/3, 310px));
  position: absolute;
  top: 43px;
  padding: 4px;
  background-color: #fff;
  border: 1px solid #d1d5d9;
  border-radius: 4px;
  z-index: 2;
}
.label-wrap {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  min-width: 142px;
  margin-top: 2px;
  padding: 6px 10px;
  font-weight: 400;
  background-color: #fff;
  transition: all 0.3s ease-out;
  border-radius: 4px;
  cursor: pointer;
  .name {
    font-size: 14px;
    line-height: 16px;
  }
  .checkbox {
    display: block;
    width: 24px;
    height: 24px;
  }
  &.active {
    background-color: #f3fbfa;
    transition: background 0.3s ease-out;
    .name {
      font-weight: 700;
    }
    .checkbox {
      background: url('https://static.sparkplus.co/hompage/common/icon/icon_option_checked.svg')
      center center no-repeat;
    }
  }
}
label {
  input {
    &:checked {
      + .label-wrap {
        background-color: #f3fbfa;
        transition: background 0.3s ease-out;
        .name {
          font-weight: 700;
        }
        .checkbox {
          background: url('https://static.sparkplus.co/hompage/common/icon/icon_option_checked.svg')
            center center no-repeat;
        }
      }
    }
  }
}
.layer-option {
  label {
    .label-wrap {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      padding: 15px 16px;
      font-weight: 400;
      background-color: #FFF;
      transition: all 0.3s ease-out;
      cursor: pointer;
      &.active {
        background-color: #f3fbfa;
      }
    }
   .name {
      font-size: 16px;
      line-height: 18px;
    }
    .checkbox {
      display: block;
      width: 24px;
      height: 24px;
    }
  }
}
// MOBILE ONLY
@media screen and (max-width: 768px) {
  .pc{display: none !important;}
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
// PC ONLY
@media screen and (min-width: 769px) {
  .mobile{display: none !important;}
  .dropdown-list {
    &[aria-hidden='false'] {
      display: block;
    }
    &[aria-hidden='true'] {
      display: none;
    }
  }
  .panel-layer {
    display: none;
  }
  .layer-option {
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height: 100%;
    overflow:auto;
    z-index: 13;
  }
}
